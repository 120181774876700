import React from "react"
import { StaticQuery, graphql } from "gatsby"

import { removePlaceholder } from "../components/helperFunctions"

const DownloadsContent = () => (
  <StaticQuery
    query={graphql`
      query DownloadsQuery {
        downloads: allContentfulDocument {
          edges {
            node {
              title
              onDownloadsPage
              file {
                file {
                  url
                }
              }
              placeholder
            }
          }
        }
      }
    `}
    render={data => {
      // Array of data from gql query
      const allDownloadsArray = data.downloads.edges
      // Define new array of data, minus placeholder content
      const allDownloadsArrayFiltered = removePlaceholder(allDownloadsArray)

      let downloadsArray = []

      /* First ensure that data.downloads object even exist, then add itmes to downloadsArray */
      if (data.downloads) {
        downloadsArray = allDownloadsArrayFiltered.filter(
          download => download.node.onDownloadsPage
        )
      }

      /* Second, ensure that the array has at least one entry */
      if (downloadsArray.length >= 1) {
        //array of documents _that are to be included on downloads page_

        const downloadsElement = downloadsArray.map((download, index) => (
          <a
            href={download.node.file.file.url}
            target="_blank"
            rel="noopener noreferrer"
            key={index}
            className="downloadsPage__link"
          >
            {download.node.title}

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="downloadsPage__icon"
            >
              <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />
              <path fill="none" d="M0 0h24v24H0z" />
            </svg>
          </a>
        ))
        return downloadsElement
      } else {
        return <p>Files unavailable for download</p>
      }
    }}
  />
)

export default DownloadsContent
